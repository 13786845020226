<template>
  <div style="height: 100vh">
    <template v-if="isMobile">
      <mobile-login
        @show-CheckDialog="showCheckDialog($event)"
        @showAgreeDialog="agrementDialogVisible = true"
        @forgotten-password="forgotPasswordDialog = true"
      />
    </template>
    <template v-else>
      <desktop-login
        @show-CheckDialog="showCheckDialog($event)"
        @showAgreeDialog="agrementDialogVisible = true"
        @forgotten-password="forgotPasswordDialog = true"
      />
    </template>

    <dialog-agrement
      :visible="agrementDialogVisible"
      @close="agrementDialogVisible = false"
    />
    <dialog-code-chack
      v-if="checkDialog"
      :visible="checkDialog"
      :sms-data="smsData"
      @close="checkDialog = false"
    />
    <dialog-password-recovery
      v-if="forgotPasswordDialog"
      :visible="forgotPasswordDialog"
      @close="forgotPasswordDialog = false"
    />
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'
import DesktopLogin from '@/views/login/components/DesktopLogin'
import DialogAgrement from '@/views/login/dialog/DialogAgrement'
import DialogCodeChack from '@/views/login/dialog/DialogCodeCheck'
import DialogPasswordRecovery from '@/views/login/dialog/DialogPasswordRecovery'
import MobileLogin from '@/views/login/components/MobileLogin'

export default {
  name: 'Login',
  components: {
    DialogAgrement,
    DialogCodeChack,
    DialogPasswordRecovery,
    MobileLogin,
    DesktopLogin,
  },
  data() {
    return {
      agrementDialogVisible: false,
      checkDialog: false,
      forgotPasswordDialog: false,

      smsData: {},
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
  methods: {
    showCheckDialog(data) {
      this.checkDialog = true
      this.smsData = data
    },
  },
}
</script>
