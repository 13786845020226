var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100vh" } },
    [
      _vm.isMobile
        ? [
            _c("mobile-login", {
              on: {
                "show-CheckDialog": function ($event) {
                  return _vm.showCheckDialog($event)
                },
                showAgreeDialog: function ($event) {
                  _vm.agrementDialogVisible = true
                },
                "forgotten-password": function ($event) {
                  _vm.forgotPasswordDialog = true
                },
              },
            }),
          ]
        : [
            _c("desktop-login", {
              on: {
                "show-CheckDialog": function ($event) {
                  return _vm.showCheckDialog($event)
                },
                showAgreeDialog: function ($event) {
                  _vm.agrementDialogVisible = true
                },
                "forgotten-password": function ($event) {
                  _vm.forgotPasswordDialog = true
                },
              },
            }),
          ],
      _c("dialog-agrement", {
        attrs: { visible: _vm.agrementDialogVisible },
        on: {
          close: function ($event) {
            _vm.agrementDialogVisible = false
          },
        },
      }),
      _vm.checkDialog
        ? _c("dialog-code-chack", {
            attrs: { visible: _vm.checkDialog, "sms-data": _vm.smsData },
            on: {
              close: function ($event) {
                _vm.checkDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.forgotPasswordDialog
        ? _c("dialog-password-recovery", {
            attrs: { visible: _vm.forgotPasswordDialog },
            on: {
              close: function ($event) {
                _vm.forgotPasswordDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }